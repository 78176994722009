<template>
	<div class="img">
		<div class="img__label">{{label}}</div>
		<div class="img__wrap">
			<div class="img__mask" v-if="!img">
				<v-icon name="plus"></v-icon>
			</div>
			<div class="img__img" v-else>
				<img :src="img" alt="">
			</div>

			<input type="file"
					tabindex="-1"
					@change="onChange"
					class="img__area">
		</div>
	</div>
</template>

<script>
	import 'vue-awesome/icons/plus';
	import VIcon from 'vue-awesome/components/Icon';

	export default {
		name: 'form-img',
		components:{
			VIcon
		},

		props: ['value', 'label'],
		data(){
			return{
				img: this.value || null
			}
		},

		methods:{
			async onChange(e){
				let file =  e.target.files[0],
					image_64 = null;

				await this.getBase64(file).then(img => {
					image_64 = img
				});

				this.img = image_64;
				this.$emit('input', image_64);
			},

			getBase64(file){
				let reader = new FileReader();
				reader.readAsDataURL(file);

				return new Promise((resolve, reject) => {
					reader.onload = function () {
						return resolve(reader.result)
					};
					reader.onerror = function (error) {
						return reject(error)
					};
				})


			}
		}
	};
</script>

<style scoped lang=scss>
	.img{
		&.height-picker{
			.img__wrap{
				height: 353px;
			}
		}
		&__wrap{
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			width: 100%;
			height: 250px;
			border-radius: 10px;
			border: 1px solid $c_border;
			padding: 10px;
			&:hover{
				.img__mask{
					border: 2px solid $c_alt;
					svg{
						fill: $c_alt;
					}
					span{
						color: $c_alt;
					}
				}
				.img__img{
					transform: scale(1.05);
					opacity: 0.8;
				}
			}
		}
		&__area{
			opacity: 0;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			cursor: pointer;
		}
		&__mask{
			width: 100%;

			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			transition: all 0.3s;
			svg{
				width: 40px;
				height: 40px;
				fill: $c_border;
			}
			span{
				font-size: 12px;
				color: $c_border;
				transition: all 0.3s;
			}
		}
		&__img{
			height: 100%;
			align-items: center;
			justify-content: center;
			display: flex;
			transition: all 0.3s;
			img{
				max-height: 100%;
			}
		}
	}

</style>
