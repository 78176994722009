import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		CARD_TYPE_PACK: 'pack',
		CARD_TYPE_HAND: 'hand',
		CARD_TYPE_TABLE: 'table',

		tableList:[],
		handList: [],
		packList: [],

		hasLoadPack: false,
		hasStartWork: false,
	},

	getters: {

	},

	mutations:{
		SET_HAND_LIST(state, list){
			state.packList = list;
		},

		ADD_CARD(state, {type, card}){
			switch(type){
				case state.CARD_TYPE_PACK:
					state.packList.push(card);

					break;
				case state.CARD_TYPE_HAND:
					state.handList.push(card);

					break;
				case state.CARD_TYPE_TABLE:
					state.tableList.push(card);

					break;
			}

			state.hasStartWork = true;
		},

		DELETE_CARD(state, {type, cardId}){
			let indexEl;

			switch(type){
				case state.CARD_TYPE_PACK:
					indexEl = $fn.findIndex(state.packList, ['id', cardId]);
					Vue.delete(state.packList, indexEl);

					break;
				case state.CARD_TYPE_HAND:
					indexEl = $fn.findIndex(state.handList, ['id', cardId]);
					Vue.delete(state.handList, indexEl);

					break;
				case state.CARD_TYPE_TABLE:
					indexEl = $fn.findIndex(state.tableList, ['id', cardId]);
					Vue.delete(state.tableList, indexEl);

					break;
			}
		},

		SET_LOADING_STATUS(state, {value_key, status}){
			state[value_key] = status;
		}
	},

	actions: {
		GET_PACKS: async({state, getters, rootGetters, commit}, id) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'hasLoadPack', status: true });

				commit('SET_HAND_LIST', [
					{
						id: 1,
						img: require('@img/cards/sur/1.jpg')
					},
					{
						id: 2,
						img: require('@img/cards/sur/2.jpg')
					},
					{
						id: 3,
						img: require('@img/cards/sur/3.jpg')
					},
					{
						id: 4,
						img: require('@img/cards/sur/4.jpg')
					},
					{
						id: 5,
						img: require('@img/cards/sur/5.jpg')
					},
					{
						id: 6,
						img: require('@img/cards/sur/6.jpg')
					},
					{
						id: 7,
						img: require('@img/cards/sur/7.jpg')
					},
					{
						id: 8,
						img: require('@img/cards/sur/8.jpg')
					},
					{
						id: 9,
						img: require('@img/cards/sur/9.jpg')
					},
					{
						id: 10,
						img: require('@img/cards/sur/10.jpg')
					},
					{
						id: 11,
						img: require('@img/cards/sur/11.jpg')
					},
					{
						id: 12,
						img: require('@img/cards/sur/12.jpg')
					},
					{
						id: 13,
						img: require('@img/cards/sur/13.jpg')
					},
					{
						id: 14,
						img: require('@img/cards/sur/14.jpg')
					},
					{
						id: 15,
						img: require('@img/cards/sur/15.jpg')
					},
					{
						id: 16,
						img: require('@img/cards/sur/16.jpg')
					},
					{
						id: 17,
						img: require('@img/cards/sur/17.jpg')
					},
					{
						id: 18,
						img: require('@img/cards/sur/18.jpg')
					},
					{
						id: 19,
						img: require('@img/cards/sur/19.jpg')
					},
					{
						id: 20,
						img: require('@img/cards/sur/20.jpg')
					}
				]);

			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'hasLoadPack', status: false });
			}
		},
	}
};
