<template>
	<div class="menu" :class="{'has-hide': !hasOpenMenu}">
		<ul class="menu__list">
			<li class="menu__item" v-for="menu in menuList" v-if="menu.hasAccess" :class="{'has-disabled': menu.hide}">
				<template v-if="menu.path">
					<div class="menu__head">
						<router-link :to="menu.path" class="menu__link ripple" :class="{'not-active': menu.path === '/' }">
							<div class="menu__icon">
								<v-icon :name="menu.icon"></v-icon>
							</div>
							<div class="menu__label">{{menu.name}}</div>
						</router-link>
					</div>

				</template>

				<template v-else>
					<div class="menu__head has-sub">
						<div class="menu__link ripple">
							<div class="menu__icon">
								<v-icon :name="menu.icon"></v-icon>
							</div>
							<div class="menu__label">{{menu.name}}</div>
						</div>
					</div>
				</template>

				<div class="menu__sub" v-if="menu.submenu">
					<ul class="sub">
						<li class="sub__item" v-for="sub in menu.submenu" v-if="sub.hasAccess">
							<router-link :to="sub.path" class="sub__link ripple">
								<div class="sub__abr">{{sub.abr}}</div>
								<div class="sub__name">{{sub.name}}</div>
							</router-link>
						</li>
					</ul>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	export default {
		name: 'header-menu',

		computed: {
			menuList(){
				return [
					{
						name: 'Робочий простір',
						hasAccess: true,
						icon: 'chart-line',
						path: '/space',
					},
					{
						name: 'Колода',
						hasAccess: true,
						icon: 'tools',
						path: '/pack',
						hide: true
					},
					{
						name: 'Профіль',
						hasAccess: true,
						icon: 'tools',
						path: '/profile',
						hide: true
					},
					{
						name: 'Питання/Відповіді',
						hasAccess: true,
						icon: 'blog',
						path: '/faq',
						hide: true
					},
				]
			},

			hasOpenMenu(){
				return this.$store.state.hasOpenMenu;
			}
		},

		mounted(){
			this.initMenu();
		},

		methods:{
			initMenu(){
				let $arrList = document.querySelectorAll('.has-sub'),
					$wrapList = document.querySelectorAll('.menu__item');

				$arrList.forEach(el => {
					el.addEventListener( "click" ,e => {
						let $arr = e.currentTarget,
							$wrap = $arr.parentElement;

						//this.$store.commit('SET_OPEN_MENU', true);

						if($wrap.classList.contains('has-active')){
							$wrap.classList.remove('has-active');
							return false;
						}

						$wrapList.forEach(item => {
							item.classList.remove('has-active');
						});

						$wrap.classList.add('has-active');
					});
				})
			}
		}

	};
</script>

<style scoped lang=scss>
	.menu{
		&.has-hide{
			.menu__head{
				&:before{
					opacity: 0;
					visibility: hidden;
				}
			}
			.menu__label{
				opacity: 0;
				visibility: hidden;
			}
			.sub__name{
				opacity: 0;
				visibility: hidden;
			}
			.menu{
				&__link{
				padding: 0 0;
				}
			}
			.sub{
				&__link{
					padding: 0 0;
				}
			}
		}
		&__item{
			margin-bottom: 8px;
			&.has-active{
				.menu__sub{
					max-height: 500px;
					opacity: 1;
					visibility: visible;
				}
				.menu__head{
					background: rgba(255,255,255,0.3);
					&:before{
						top: 22px;
						transform: rotate(180deg);
					}
				}
			}
		}
		&__link{
			display: flex;
			align-items: center;
			cursor: pointer;
			overflow: hidden;
			position: relative;
			padding: 0 20px;
			&.router-link-active{
				background: #000000;
			}
			&.not-active{
				background: none;
			}
			&.router-link-exact-active{
				background: #000000;
			}
		}
		&__head{
			position: relative;
			transition: all 0.3s;
			&:before{
				content: '';
				display: none;
				position: absolute;
				right: 25px;
				top: 23px;
				width: 0;
				height: 0;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 4px solid #fff;
				transition: all 0.3s;
			}
			&:hover{
				background: rgba(255,255,255,0.1);
			}
			&.has-sub{
				&:before{
					display: block;
				}
			}
		}
		&__icon{
			width: 48px;
			height: 48px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			svg{
				width: 17px;
				height: 17px;
				fill: #fff;
			}
		}
		&__label{
			color: #fff;
			transition: all 0.3s;
			position: relative;
			font-size: 14px;
		}
		&__sub{
			max-height: 0;
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s;
		}
	}

	.sub{
		padding-top: 10px;
		&__item{
			margin-bottom: 10px;
		}
		&__link{
			display: flex;
			align-items: center;
			height: 48px;
			transition: all 0.3s;
			overflow: hidden;
			position: relative;
			padding: 0 20px;
			&:hover{
				background: rgba(255,255,255,0.2);
			}
			&.router-link-active{
				background: #000000;
			}
		}
		&__abr{
			width: 48px;
			text-align: center;
			color: #fff;
			font-family: $f_alt;
			flex-shrink: 0;
			text-transform: uppercase;
		}
		&__name{
			font-size: 14px;
			color: #fff;
			position: relative;
			transition: all 0.3s;
			display: block;
			white-space: nowrap;

		}
	}
</style>
