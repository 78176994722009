import Vue from 'vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import specific icons */
import { faChartLineUp, faCartShopping, faUser, faEnvelope, faMoneyBill, 
        faClipboard, faPrint, faFolder, faBriefcase, faBook,
        faScrewdriverWrench, faUsers, faGear, faListRadio, faSitemap,
        faBusinessTime, faBolt, faRotate, faXmark, faSort, 
        faArrowUp, faTrash, faPlusCircle, faCheck, faTriangleExclamation, 
        faUpload, faDownload, faSortDown, faCalendarDay, faMagnifyingGlass, 
        faPen, faPenToSquare, faEllipsisVertical, faBars, faCalculator, 
        faGlobe, faBell, faArrowRightFromBracket, faArrowLeftLong, faEye, 
        faCircleExclamation, faCopy, faIdCard, faPalette, faPencil,
        faPhone, faGlobeAmericas, faEnvelopesBulk, faArrowsUpDown, faChartLine, 
        faCircleInfo, faPaperclip, faPlus, faClipboardList, faChartBar,
		faFileExport, faBarsFilter, faArrowRightLong,
		faArchive, faArrowDown, faCalendarDays, faCoins, faFileChartColumn ,faRepeat,
		faFileImport, faHourglassStart, faMoneyCheckDollarPen, faMoneyCheckDollar,
		faFilter, faArrowsRotate, faCardSpade} from '@fortawesome/pro-regular-svg-icons';

import { faArrowDown as faArrowDownSolid } from '@fortawesome/pro-solid-svg-icons';

import { faFacebook, faInstagram, } from '@fortawesome/free-brands-svg-icons';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* add icons to the library */
library.add(faChartLineUp, faCartShopping, faUser, faEnvelope, faMoneyBill, 
        faClipboard, faPrint, faFolder, faBriefcase, faBook,
        faScrewdriverWrench, faUsers, faGear, faListRadio, faSitemap,
        faBusinessTime, faBolt, faRotate, faXmark, faSort, 
        faArrowUp, faTrash, faPlusCircle, faCheck, faTriangleExclamation, 
        faUpload, faDownload, faSortDown, faCalendarDay, faMagnifyingGlass, 
        faPen, faPenToSquare, faEllipsisVertical, faBars, faCalculator, 
        faGlobe, faBell, faArrowRightFromBracket, faArrowLeftLong, faEye,
        faCircleExclamation, faCopy, faIdCard, faPalette, faPencil,
        faPhone, faFacebook, faGlobeAmericas, faInstagram, faEnvelopesBulk, 
        faArrowsUpDown, faChartLine, faCircleInfo, faPaperclip, faPlus, 
        faClipboardList, faChartBar, faFileExport, faBarsFilter, faArrowRightLong,
		faArchive, faArrowDown, faCalendarDays, faCoins, faFileChartColumn, faRepeat,
		faFileImport, faHourglassStart, faMoneyCheckDollarPen, faMoneyCheckDollar,
		faArrowDownSolid, faFilter, faArrowsRotate, faCardSpade);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);
