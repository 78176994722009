import Vue from './vue_base';

import VIcon from 'vue-awesome/components/Icon';
Vue.component('v-icon', VIcon);

import FormInput from '@form/FormInput';
Vue.component('form-input', FormInput);

import FormTextarea from '@form/FormTextarea';
Vue.component('form-textarea', FormTextarea);

import FormEditor from '@form/FormEditor';
Vue.component('form-editor', FormEditor);

import FormSelect from '@form/FormSelect';
Vue.component('form-select', FormSelect);

import FormSelectGroup from '@form/FormSelectGroup';
Vue.component('form-select-group', FormSelectGroup);

import FormSelectOrAdd from '@form/FormSelectOrAdd';
Vue.component('form-select-or-add', FormSelectOrAdd);

import FormSelectTags from '@form/FormSelectTags';
Vue.component('form-select-tags', FormSelectTags);

import FormSelectTagsOrAdd from '@form/FormSelectTagsOrAdd';
Vue.component('form-select-tags-or-add', FormSelectTagsOrAdd);

import FormCheckbox from '@form/FormCheckbox';
Vue.component('form-checkbox', FormCheckbox);

import FormImg from '@form/FormImg';
Vue.component('form-img', FormImg);

import FormColorPicker from '@form/FormColorPicker';
Vue.component('form-color-picker', FormColorPicker);

import FormTimeRange from '@form/FormTimeRange';
Vue.component('form-time-range', FormTimeRange);

import FormFile from '@form/FormFile';
Vue.component('form-file', FormFile);

import AlertModal from '@common/components/AlertModal';
Vue.component('alert-modal', AlertModal);

import VTable from '@components/VTable';
Vue.component('v-table', VTable);
