import Vue from 'vue';
import VueRouter from 'vue-router';

import Dashboard from '@views/dashboard/DashboardIndex.vue';
import LoginIndex from '@views/LoginIndex';

import store from '../store';

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		name: 'login',
		component: LoginIndex,
		meta: {
			layout: store.state.KEY_LAYOUT_LOGIN,
			requiresFree: true
		}
	},
	{
		path: '/',
		name: 'dashboard',
		component: Dashboard
	},
	{
		path: '/board/:id',
		name: 'boarder_key',
		component: () => import(/* webpackChunkName: "BoardIndex" */ '@views/board/BoardIndex'),
		props: true,
		meta: {
			layout: store.state.KEY_LAYOUT_BOARD
		}
	},
	{
		path: '/space',
			name: 'space',
		component: () => import(/* webpackChunkName: "space" */ '@views/space/SpaceIndex'),
		meta:{
	}
	},
	{
		path: '/pack',
		name: 'pack',
		component: () => import(/* webpackChunkName: "pack" */ '@views/pack/PackIndex'),
		meta:{
		}
	},
	{
		path: '/profile',
		name: 'profile',
		component: () => import(/* webpackChunkName: "profile" */ '@views/profile/ProfileIndex'),
		meta:{
		}
	},
	{
		path: '/faq',
		name: 'faq',
		component: () => import(/* webpackChunkName: "faq" */ '@views/faqs/FaqsIndex'),
		meta:{
		}
	},

	//404-------------------------/
	{
		path: '*',
		redirect: '/404'
	},
	{
		path: '/404',
		component: () => import(/* webpackChunkName: "404" */ '@views/error404'),
		meta: {
			layout: store.state.KEY_LAYOUT_ERROR
		}
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve({x: 0, y: 0});
			}, 200);
		});
	}
});

router.beforeEach((to, from, next) => {
	next();

	// if (to.meta.requiresFree === true) {
	// 	next();
	//
	// } else {
	// 	if (store.getters.hasLogged){
	// 		next();
	//
	// 		if (to.meta.permissionKey){
	// 			let permissions =  window.$permissions || JSON.parse(localStorage.getItem('_permissions_druk_crm')) || null;
	//
	// 			let keys = to.meta.permissionKey.split(',');
	//
	// 			let access = false;
	// 			keys.forEach(el => {
	// 				if (permissions.admin[el]) access = true;
	// 			})
	//
	// 			if(permissions &&  access){
	// 				next()
	// 			} else{
	// 				next({name: 'dashboard'})
	// 			}
	//
	// 		} else {
	// 			next()
	// 		}
	//
	//
	// 	} else {
	// 		next({name: 'login'});
	// 	}
	// }
});

export default router;
