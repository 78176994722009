import Vue from 'vue';
import ordersList from '@/database/orders.js';

export default {
	namespaced: true,
	state: {
		ORDER_PRE_KEY: '#MY32R',

		activeOrders: [ordersList[0],ordersList[1]],
		list: ordersList,

		item: ordersList[0],
		listPagination: {},
		listFilter: {
			page: 1,
			limit: 999,
			sort_price: null,
			sort_date: null,
			game: null,

			order_by: 'created_at',
			order_dir: 'desc'
		},

		loadingGetList: false,
		loadingGetItem: false,
	},

	getters: {
		listActiveFilter(state){
			let filter = {};

			$fn.forIn(state.listFilter, (val, key) => {
				if (val !== null && val !== '' ) filter[key] = val;
			});
			return filter
		}
	},

	mutations:{
		SET_LIST(state, list){
			state.list = list;
		},
		SET_ITEM(state, item){
			state.item = item;
		},
		SET_PAGINATION(state, pagination){
			state.listPagination = pagination;
		},
		SET_FILTER_LIST(state, {key, val}){
			state.listFilter[key] = val
		},

		SET_LOADING_STATUS(state, {value_key, status}){
			state[value_key] = status;
		}
	},

	actions: {
		GET_LIST: async({state, rootGetters, getters, commit}) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

				if(state.list.length === 1 && getters.listActiveFilter.page > 1){
					state.listFilter.page = state.listFilter.page -1;
				}

				let resp = await $axios.get(`/clients/authorized/orders`, {
					params: getters.listActiveFilter

				});

				commit('SET_LIST', resp.list);
				commit('SET_PAGINATION', resp.pagination);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
			}
		},

		GET_ITEM: async({state, getters, rootGetters, commit}, id) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

				let resp = await $axios.get(`/api/faq/${id}`);

				commit('SET_ITEM', resp.single);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
			}
		},
	}

};
