export default [
	{
		id: 1,
		title: 'Простiр метафор',
		active: true
	},
	{
		id: 2,
		title: 'В очiкуваннi',
		active: false
	},
	{
		id: 3,
		title: 'В очiкуваннi'
	}
]
