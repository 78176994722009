<template>
	<div class="header">
		<div class="header__main">
			<div class="header__navs">
				<div class="header__nav">
					<button class="btn btn--icon" @click="hasOpenMenu = !hasOpenMenu">
						<v-icon v-if="hasOpenMenu" name="ellipsis-v"></v-icon>
						<v-icon v-else name="bars"></v-icon>
					</button>
				</div>
			</div>

		</div>

		<div class="header__options">
			<div class="header__option">
				<button class="btn ripple clRipple btn--icon" @click="onLogout">
					<v-icon name="sign-out-alt"></v-icon>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
	import DropdownMenu from '@components/DropdownMenu';

	export default {
		name: 'header-top',
		components: {
			DropdownMenu, 
		},

		computed: {
			...mapState({
				activeOrders: state => state.orders.activeOrders,
				user: state => state.auth.user,
			}),

			hasOpenMenu: {
				get () {
					return this.$store.state.hasOpenMenu;
				},
				set (status) {
					this.SET_OPEN_MENU(status);
				}
			},

		},

		methods: {

			...mapMutations({
				SET_OPEN_MENU: 'SET_OPEN_MENU',
			}),

			...mapActions({
				AUTH_LOGOUT: 'AUTH_LOGOUT'
			}),

			onLogout () {
				this.AUTH_LOGOUT().then(() => {
					this.$router.push({name: 'login'});
				});
			},

			onSetLang (locale) {
				this.$i18n.setLang(locale).then(() => {
					this.$bus.$emit('dropdown-hide');
					window.location.reload();
				});
			},

		}
	};
</script>

<style scoped lang=scss>
	.header {
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
		/* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
		background: #DCD2E4;
		&__options {
			display: flex;
			align-items: center;
		}
		&__main {
			display: flex;
			align-items: center;
		}
		&__navs{
			display: flex;
			align-items: center;
		}
	}

	.list {
		width: 250px;
		&__item {
			font-size: 12px;
			padding: 10px 10px;
			transition: all 0.1s;
			display: flex;
			align-items: center;
			border-radius: 5px;
			cursor: pointer;
			&.has-active {
				background: $c_alt_light;
				pointer-events: none;
			}
			&:hover {
				background: $c_alt;
				color: #f0f0f0;
				/* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
			}
		}

		&--lang {
			width: 43px;
			.list__item {
				text-transform: uppercase;
				justify-content: center;
			}
		}
	}

	.menu {
		padding-right: 10px;
		margin-right: 10px;
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 9px;
			right: 0;
			width: 1px;
			background: $c_border;
			bottom: 9px;
		}
		&__list {
			display: flex;
			align-items: center;
		}
		&__link {
			padding: 5px;
			line-height: 0;
			display: flex;
		}
	}
	.has-mobile{
		@media (max-width: $md) {
			display: none;
		}
	}

	.orders{
		display: flex;
		align-items: center;
		&__list{
			display: flex;
			align-items: center;
		}
		&__item{
			display: flex;
			margin-right: 20px;
			cursor: pointer;
			&:hover{
				.orders__icon{
					box-shadow: 0 1px 8px rgba(0,0,0,0.4);
				}
				.orders__service{
					transform: translateX(10px);
				}
				.orders__title{
					color: $c_text;
				}
			}
		}
		&__mainTitle{
			font-size: 14px;
			font-weight: bold;
			text-transform: uppercase;
			margin-right: 40px;
			color: rgba(0,0,0,0.2);
		}
		&__service{
			font-family: $f_alt;
			color: $c_alt;
			font-size: 12px;
			transition: all 0.3s;
		}
		&__title{
			font-size: 12px;
			font-family: $f_alt;
			color: $c_text_light;
			transition: all 0.3s;
		}
		&__icon{
			width: 38px;
			line-height: 0;
			margin-right: 10px;
			border-radius: 50%;
			transition: all 0.3s;
		}
	}

	.coin{
		display: flex;
		align-items: center;
		margin-left: 40px;
		cursor: pointer;
		&:hover{
			opacity: 0.8;
		}
		&__title{
			color: $c_text_light;
			text-transform: uppercase;
			font-size: 12px;
			margin-right: 5px;
		}
		&__price{
			font-family: $f_alt;
			color: $c_alt;
		}
		&__nav{
			margin-left: 5px;
			svg{
				fill: $c_alt
			}
		}
	}
</style>
