<template>
	<div class="dashboard">
		<div class="container">
			<div class="dashboard__title">Вiтаю</div>
			<div class="dashboard__sub">Для початку роботи оберіть робочий простiр</div>

			<div class="dashboard__space">
				<space-list></space-list>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';
	import SpaceList from '@views/space/SpaceList';

	export default {
		name: 'dashboard-index',
		components:{
			SpaceList
		}
	};
</script>

<style scoped lang="scss">
	.dashboard{
		padding: 100px 0 20px;
		.container{
			max-width: 920px;
			width: 100%;
			margin: 0 auto;
		}
		&__title{
			text-align: center;
			font-size: 28px;
			font-family: $f_alt;
			color: $c_alt;
		}
		&__sub{
			text-align: center;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 500;

		}
		&__link{
			text-align: center;
			a{
				text-decoration: underline;
			}
		}
		&__space{
			padding-top: 20px;
		}
	}

	.orders{
		&__title{
			font-family: $f_alt;
		}

		&__item{
			margin-bottom: 10px;
		}
		&__empty{
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		&__emptyText{
			font-family: $f_alt;
		}
	}
</style>
