<template>
	<div class="bLayout">
		<main class="bLayout__main">
			<router-view></router-view>
		</main>
	</div>
</template>

<script>
	export default {
		name: 'board-layout'
	};
</script>

<style scoped lang=scss>
	.bLayout{
		height: 100%;
		&__main{
			height: 100%;
		}
	}
</style>
