import Vue from './vue_base';

Vue.filter('numberNormalize', function (value) {
  if (!value) return '';

  if (value - Math.floor (value)){
	return value;

  } else {
	return Math.trunc(value);
  }
});

Vue.filter('numberPrice', function (value) {
	if (!value) return '';

	return value.toFixed(2);
});

