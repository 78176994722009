<template>
	<div class="wrapper" :class="{'has-openMenu' : hasOpenMenu}">
		<header class="wrapper__header">
			<header-index></header-index>
			<header-top></header-top>
		</header>

		<main class="wrapper__main">
			<transition name="fade" mode="out-in">
				<router-view :key="$route.path"></router-view>
			</transition>
		</main>
	</div>
</template>

<script>
    import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import HeaderIndex from '@common/header/HeaderIndex';
	import HeaderTop from '@common/header/HeaderTop';

	export default {
		name: 'default-layouts',
		components: {
			HeaderIndex,
			HeaderTop
		},

		computed: {
		  ...mapState({
			hasOpenMenu: state => state.hasOpenMenu
		  }),
		}
	};
</script>

<style scoped lang="scss">
	.wrapper {
		padding-left: 68px;
		transition: all 0.3s;
		background-color: #ffffff;
		&.has-openMenu {
			padding-left: 250px;
			@media (max-width: $md){
				overflow: hidden;
			}
		}
		&__header{
			position: relative;
			z-index: 2;
		}
		&__main{
			/*position: relative;*/
			/*z-index: 1;*/
		}
		@media (max-width: $md) {
			padding-left: 0;
		}
	}

	// #particle-canvas {
	// 	position: fixed !important;
	// 	left: 0;
	// 	top: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	background-color: #ffffff;
	// }

	// #particle-canvas > div {
	// 	background: red;
	// }
</style>
