import Vue from './vue_base';
import {forIn} from 'lodash';
import axios from 'axios';
import router from '../router';

import {loadProgressBar} from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';

loadProgressBar();

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API;

axios.interceptors.request.use((config) => {
	config.headers['Accept-Language'] = 'en';

	let token = localStorage.getItem('_token_boost_booster');

	if (token) {
		config.headers['Authorization'] = token;
	}

	return config;
});

axios.interceptors.response.use((resp) => {
	let requestMethod = resp.config.method;

	switch (requestMethod){
		case 'post':
			$VUE_APP.$noty.success('Операция прошла успешно');
			break;

		case 'delete':
			$VUE_APP.$noty.success('Успешно удалено');
			break;
	}

	if (resp.data.message) {
		$VUE_APP.$noty.success(resp.data.message);
	}
	return resp.data;

}, function (err) {
	let status = err.response.status;
	if (status === 401) {
		console.warn('Unauthorized');

	} else {
		if (err.response.data.message && typeof(err.response.data.message) === 'object') {
			forIn(err.response.data.message, (item, key) => {
				$VUE_APP.$noty.error(item);
			});

		} else if (err.response.data.message && typeof(err.response.data.message) === 'string') {
			$VUE_APP.$noty.error(err.response.data.message);
		}
		return Promise.reject(err);
	}
});

Vue.prototype.$axios = axios;
window.$axios = axios;
export default axios;

