import store from '../../../store/auth';
export default class UploadAdapter {
	constructor( loader ) {
		this.loader = loader;
	}

	upload() {
		return this.loader.file
			.then( uploadedFile => {
				return new Promise( ( resolve, reject ) => {
				const data = new FormData();
				data.append( 'file', uploadedFile );
	
				$axios( {
					url: `/api/images`,
					method: 'post',
					data,
					headers: {
						'Content-Type': 'multipart/form-data;'
					},
					withCredentials: false
				} ).then( response => {
					if ( response.status === 'success') {
						resolve( {
							default: response.path
						} );
					} else {
						reject( response.data.message );
					}
				} ).catch( response => {
					reject( 'Upload failed' );
				} );
	
			} );
		} );
	}
}
