<template>
	<div class="login">
		<div class="form">
			<div class="form__head">
				<img :src="require('@img/logo_mini.png')" alt="">
				<div class="form__title">Кабiнет психолога</div>
			</div>

			<div class="form__item">
				<form-input v-model="formData.name"
						label="Логін"
						rules="required"
						@enter="onLogin"
						name="name"></form-input>
			</div>

			<div class="form__item">
				<form-input v-model="formData.password"
						type="password"
						label="Пароль"
						rules="required"
						@enter="onLogin"
						name="password"></form-input>
			</div>

			<div class="form__footer">
				<button class="btn"
						:class="{'has-loading': hasLoadingLogin}"
						@click="onLogin"><span>Вхiд</span></button>
			</div>
		</div>
	</div>
</template>

<script>
    import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';
	import moment from 'moment';

	export default {
		name: 'login-index',
		data () {
			return {
				VALID_LOGIN: 'wish',

				formData:{
					name: null,
					password: null,
				}
			};
		},

	    computed: {
		    ...mapState({
			    KEY_LAYOUT_LOGIN: state => state.KEY_LAYOUT_LOGIN,
			    hasLoadingLogin: state => state.auth.hasLoadingLogin
		    }),

		    hasValidAccess(){
		    	return true; //@TODO need delete

		    	let hasLoginTrue = this.formData.name === this.VALID_LOGIN;

		    	let nowDate = new Date;
				let date = moment(nowDate, 'YYYY/MM/DD');
			    let day = date.date(),
				    month = date.month() + 1;

				let hasPassTrue = this.formData.password === `9${day}9${month}9`;

				return hasLoginTrue && hasPassTrue;
		    }
	    },

		created () {
		    this.SET_LAYOUT(this.KEY_LAYOUT_LOGIN);
		},

		methods: {
		    ...mapMutations({
			    SET_LAYOUT: 'SET_LAYOUT'
		    }),
		    ...mapActions({
			    AUTH_LOGIN: 'AUTH_LOGIN'
		    }),

			onLogin () {
		    	if(this.hasValidAccess){
					this.$noty.success('Вітаємо в вашому кабінеті!');
					this.$router.push({name: 'dashboard'});

			    } else {
		    		this.$noty.error('Невірно вказаний логін або парол!')
			    }


				// this.$validator.validate().then(result => {
				// 	if (result) {
				// 	  this.AUTH_LOGIN(this.formData).then(() => {
				// 		this.$router.push({name: 'dashboard'});
				//
				// 	  }).catch(err => {
				// 		  $fn.setValidateErrors(err, this.errors);
				// 	  });
				// 	}
				// });
			}
		}
	};
</script>

<style scoped lang=scss>
	.form{
		&__head{
			max-height: 40px;
			img{
				transform: translateY(-10px);
			}
		}
	}
	.login {
		max-width: 550px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-grow: 1;
		&__error {
			height: 30px;
			text-align: center;
		}
	}
</style>
