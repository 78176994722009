export default {
	namespaced: true,
	state: {

	},

	getters: {

	},

	mutations:{

	},

	actions: {}
};
