<template>
	<router-link :to="`board/${space.id}`" class="card" :class="{'has-disabled': !space.active}" :style="{'background-image': `url(${require('@img/card_bg.png')}`}">
		<div class="card__title">{{space.title}}</div>
	</router-link>
</template>

<script>
	export default {
		name: 'space-card',
		props: ['space']
	};
</script>

<style scoped lang=scss>
	.card{
		height: 150px;
		width: 100%;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: $f_alt;
		font-size: 28px;
		padding: 10px 20px;
		&__title{
			text-align: center;
		}
	}
</style>
