<template>
	<div class="alert" >
		<div class="alert__scope" @click="hasOpen = true">
			<slot></slot>
		</div>

		<div class="alert__main"  :class="{'has-active': hasOpen}">
			<div class="alert__dim" @click="hasOpen = false"></div>

			<div class="alert__cnt">
				<div class="alert__text">Вы уверены?</div>
				<div class="alert__footer">
					<button class="btn btn--sm" @click="onSuccess">Да</button>
					<button class="btn btn--sm" @click="hasOpen = false">Нет</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'alert-modal',
		data(){
			return {
				hasOpen: false
			}
		},

		methods:{
			onSuccess(){
				this.$emit('success');
				this.hasOpen = false;
			}
		}
	};
</script>

<style scoped lang=scss>
	.alert{
		&__dim{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.8);
		}
		&__main{
			position: fixed;
			left: 0;
			top: 0;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			z-index: 999;
			display: none;
			&.has-active{
				display: flex;
			}
		}
		&__cnt{
			position: relative;
			background: #fff;
			padding: 30px;
			border-radius: 10px;
		}
		&__text{
			margin-bottom: 25px;
			text-align: center;
		}
		&__footer{
			display: flex;
			align-items: center;
			.btn{
				margin: 0 5px;
			}
		}
	}
</style>
