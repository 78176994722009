import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
import {forIn} from 'lodash';

import auth from '@/store/auth';
import orders from '@/store/modules/orders';
import faqs from './modules/faqs';
import profile from '@/store/modules/profile';
import board from '@/store/modules/board';

export default new Vuex.Store({
	modules: {
		auth,
		orders,
		faqs,
		profile,
		board
	},

	state: {
		WEBSITE_URL: process.env.VUE_APP_WEBSITE_URL,

		KEY_LAYOUT_DEFAULT: 'default-layout',
		KEY_LAYOUT_LOGIN: 'login-layout',
		KEY_LAYOUT_BOARD: 'board-layout',
		KEY_LAYOUT_ERROR: 'error-layout',

		DEFAULT_LANG: {
			code:"en",
			name:"English"
		},

		layout: 'default-layout',
		selectedLang : 'en',
		selectedLangObj : {
			code:"en",
			name:"English"
		},
		hasOpenMenu: true
	},

	mutations: {
		SET_LAYOUT (state, payload) {
			state.layout = payload;
		},

		SET_OPEN_MENU (state, status) {
			state.hasOpenMenu = status;
		},

		SET_SELECTED_LANG (state, selectedLang) {
			state.selectedLang = selectedLang;
		},
	}
});
