<template>
	<div class="wrapper" :style="{'background-image': 'url(' + require('@img/login_bg.jpg') + ')'}">
		<main class="wrapper__main">
			<router-view></router-view>
		</main>

		<canvas id="loginCanvas"></canvas>
	</div>
</template>

<script>
	export default {
		name: 'login-layout',
	}
</script>

<style scoped lang="scss">
	canvas{
		width: 100%;
		height: 50%;
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
	}
	.wrapper{
		min-height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		position: relative;
		&:before{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.2);
		}
		&__main{
			width: 100%;
			position: relative;
			z-index: 2;
		}
	}
</style>
