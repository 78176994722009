import Vue from 'vue';

export default {
    state: {
	    ROLE_DEV: 'dev',
	    ROLE_ADMIN: 'admin',
		ROLE_BOOSTER: 'booster',

        token: localStorage.getItem('_token_wish_workspace') || null,
	    user: JSON.parse(localStorage.getItem('_user_wish_workspace')) || null,
		permissions: JSON.parse(localStorage.getItem('_permissions_wish_workspace')) || null,

	  	hasLoadingLogin: false,
		hasLoadingPermissions: false
    },

    getters: {
        hasLogged: state => {
            return state.token !== null;
        },

	    hasUser(state){
        	return !!state.user
	    },

		hasAdminTypeTypography(state){
			return !!state.user &&  !!state.user.typography && !!state.user.typography.id
		},
		currentTypography(state, getters){
        	if (!getters.hasAdminTypeTypography) return null;
        	return state.user.typography
		},

	    accessDev(state){
        	if (!state.user) return false;
        	return state.user.role === state.ROLE_DEV
	    },

	    accessAdmin(state){
		    if (!state.user) return false;
		    return state.user.role === state.ROLE_ADMIN
	    }
    },

    mutations: {
        SET_TOKEN (state, token) {
            state.token = token;
        },

	    SET_USER (state, user) {
		    state.user = user;
	    },

		SET_PERMISSIONS (state, permissions) {
			state.permissions = permissions;
			//set global permissions
			window.$permissions = permissions;
		},

		SET_STATUS_PERMISSIONS(state, status){
        	state.hasLoadingPermissions = status
		},

        LOGOUT(state){
            state.token = null;
            state.user = null;
            state.permissions = null;
        },
    },

    actions: {
		AUTH_LOGIN: async({rootState, rootGetters, state, getters, commit}, formData) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'hasLoadingLogin', status: true });

				let resp = await $axios.post('/api/staff/auth/login', formData);

				let token = 'Bearer ' + resp.token;

				localStorage.setItem('_token_wish_workspace', token);
				$axios.defaults.headers.common['Authorization'] = token;
				commit('SET_TOKEN', token);


				let user = resp.staff;
				localStorage.setItem('_user_wish_workspace', JSON.stringify(user));
				commit('SET_USER', user);

				state.hasLoadingLogin = false;

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'hasLoadingLogin', status: false });
			}
		},

        AUTH_LOGOUT({commit}){
            return new Promise((resolve, reject) => {
                commit('LOGOUT');

                localStorage.removeItem('_token_wish_workspace');
                localStorage.removeItem('_user_wish_workspace');
                localStorage.removeItem('_permissions_wish_workspace');
                delete $axios.defaults.headers.common['Authorization'];

                resolve();
            })
        }
    }
};
