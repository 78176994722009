<template>
	<div class="space">
		<ul class="space__list">
			<li class="space__item" v-for="space in spaces">
				<space-card :space="space"></space-card>
			</li>
		</ul>
	</div>
</template>

<script>
	import spaces from '@/database/spaces.js';
	import SpaceCard from './SpaceCard';

	export default {
		name: 'space-list',
		components:{
			SpaceCard
		},
		data(){
			return{
				spaces: spaces
			}
		}
	};
</script>

<style scoped lang=scss>
	.space{
		&__list{
			display: flex;
			flex-wrap: wrap;
		}
		&__item{
			padding: 0 5px;
			margin-bottom: 10px;
			width: 33.333%;
		}
	}
</style>
